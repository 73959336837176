<template>
  <div>
    <el-row>
      <el-col v-loading="loading" :span="10" class="desktop-cols">
        <el-card>
          <label style="line-height: 40px">Источники</label>
          <el-checkbox v-model="colored" style="margin-left: 20px">Подсветка</el-checkbox>
          <el-select
            v-model="params.source_classification_id"
            placeholder="Выберите тип КПР"
            style="float: right"
            clearable
            @change="fetchData">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-divider/>
          <simple-table
            :list="sources"
            name="sources"
            :show-pagination="false"
            :columns="sourcesColumns"
            expand-table
            :colored="colored"
            :inner-columns="innerColumns"/>
        </el-card>
      </el-col>
      <el-col v-loading="loading" :span="8" class="desktop-cols">
        <el-card>
          <label style="line-height: 40px">ИОГВ</label>
          <el-select
            v-model="params.iogv_classification_id"
            placeholder="Выберите тип КПР"
            style="float: right"
            clearable
            @change="fetchData">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-divider/>
          <simple-table :list="iogv" name="ebosp" :show-pagination="false" :columns="ebospColumns"/>
        </el-card>
      </el-col>
      <el-col :span="6" class="desktop-cols">
        <el-card>
          <Chart v-if="stats.data.length > 0" :options="chartData" class="chart" height="500px"/>
          <el-divider/>
          <div v-for="item in stats.data" :key="item.name">
            <p><b>{{`${item.name}`}}</b></p>
            <label>{{`${item.completed}/${item.should_be_entered}`}}</label>
            <el-progress :percentage="Math.round(item.percent)" color="#1ab394"></el-progress>
            <el-divider/>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Chart from '@/components/Chart'
import SimpleTable from '@/components/SimpleTable'
import { getDesktop } from '@/api/api'

export default {
  components: {
    Chart,
    SimpleTable
  },
  data () {
    return {
      options: [
        {
          label: 'Общий',
          value: 1
        },
        {
          label: 'Индивидуальный',
          value: 2
        }
      ],
      params: {
        period_id: null,
        source_classification_id: null,
        iogv_classification_id: null
      },
      colored: false,
      loading: true,
      sources: [],
      iogv: [],
      stats: {
        data: [],
        total: {
          completed: 0,
          should_be_entered: 0
        }
      },
      sourcesColumns: [
        {
          field: 'name',
          label: 'ИОГВ',
          align: 'left'
        },
        {
          field: 'should_be_entered',
          label: 'Должно быть введено'
        },
        {
          field: 'completed',
          label: 'Уже введено'
        },
        {
          field: 'percent',
          label: 'Процент',
          type: 'percent'
        }
      ],
      innerColumns: [
        {
          field: 'name',
          label: 'Сотрудник',
          align: 'left'
        },
        {
          field: 'should_be_entered',
          label: 'Должно быть введено'
        },
        {
          field: 'completed',
          label: 'Уже введено'
        },
        {
          field: 'percent',
          label: 'Процент',
          type: 'percent'
        }
      ],
      ebospColumns: [
        {
          field: 'name',
          label: 'ИОГВ',
          align: 'left'
        },
        {
          field: 'should_be_entered',
          label: 'Должно быть введено'
        },
        {
          field: 'completed',
          label: 'Уже введено',
          width: '100px'
        },
        {
          field: 'percent',
          label: 'Процент',
          type: 'percent'
        }
      ]
    }
  },
  mounted () {
    this.fetchData()
  },
  computed: {
    chartData () {
      return {
        title: {
          text: 'Ход заполнения',
          subtext: `${this.stats.total.completed}/${this.stats.total.should_be_entered}`,
          left: 'left',
          textStyle: {
            color: '#303133',
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif'
          },
          subtextStyle: {
            color: '#303133',
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif'
          }
        },
        tooltip: {
          trigger: 'item',
          valueFormatter: (value) => value
        },
        series: [
          {
            type: 'pie',
            radius: '70%',
            top: 50,
            data: [
              { value: this.stats.total.completed, name: 'Заполнено' },
              { value: this.stats.total.should_be_entered - this.stats.total.completed, name: 'Не заполнено' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      getDesktop(this.params).then(data => {
        this.sources = data.data.sources
        this.iogv = data.data.iogv
        this.stats = data.data.stats
        this.loading = false
      })
    }
  }
}

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.desktop-cols {
  padding: 5px;
}
.chart {
  z-index: 100;
}
</style>
